<template>
  <div class="persons-page" v-if="currentAccount">
    <h1>Persons</h1>
    <hr />

    <div class="d-flex align-items-center align-content-center mb-2">
      <button v-if="currentAccount.hasPermission('persons.create')" class="person-create btn btn-primary float-right" @click="createPerson">
        Create new
      </button>

      <div class="ms-3 form-check">
        <label for="deletedOnly" class="form-check-label">Archived only</label>
        <input type="checkbox" class="form-check-input" id="deletedOnly" v-model="deletedOnly" />
      </div>
    </div>

    <div>
      <input type="text" class="form-control search" placeholder="Search" v-model="search" @input="onSearch" />
      <ag-grid-vue
        class="ag-theme-material"
        style="height: 75vh"
        rowModelType="serverSide"
        :gridOptions="gridOptions"
        :getRowId="getRowId"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapGetters } from 'vuex';
import { personService } from '@/modules/person/person.service';
import { getPersonList, deletePerson, restorePerson } from '@/api';
import ActionsCellRenderer from '@/components/ActionsCellRenderer';
import { debounce } from '@/utils';

export default {
  name: 'PersonsPage',
  components: { AgGridVue, ActionsCellRenderer },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    gridApi: null,
    getRowId: params => `${params.data.id}`,
    gridOptions: {
      suppressContextMenu: true,
      suppressCellFocus: true,
      enableBrowserTooltips: true,
      cacheBlockSize: 30,
      maxBlocksInCache: 5,
      rowBuffer: 0,
      onCellClicked: async event => {
        if (!event.colDef.copyOnClick) {
          return;
        }

        var textArea = document.createElement('textarea');
        textArea.value = event.value;

        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          if (document.execCommand('copy')) {
            event.context.$toast.success(`${event.colDef.headerName} copied`);
          }
        } catch (err) {
          event.context.$toast.error(err.message);
        }

        document.body.removeChild(textArea);
      },
      onColumnMoved: async event => {
        event.context.saveColumnsState(event.columnApi);
      },
      onColumnVisible: async event => {
        event.context.saveColumnsState(event.columnApi);
      },
      onColumnResized: async event => {
        if (!event.finished) {
          return;
        }

        event.context.saveColumnsState(event.columnApi);
      },
      onColumnPinned: async event => {
        event.context.saveColumnsState(event.columnApi);
      },
    },
    defaultColDef: {
      resizable: true,
      copyOnClick: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    search: '',
    deletedOnly: false,
  }),
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;

      this.gridOptions.context = this;

      this.gridApi.setDefaultColDef(this.defaultColDef);

      this.setColumns();
      this.applyColumnsState(params.columnApi);

      this.gridApi.setServerSideDatasource({
        parent: this,
        async getRows(params) {
          try {
            const startRowIndex = params.request.startRow;
            const endRowIndex = params.request.endRow;
            const expectedRowCount = endRowIndex - startRowIndex;
            const currentRowCount = params.api.getServerSideStoreState()[0]?.rowCount || 0;

            const response = await getPersonList(
              expectedRowCount,
              params.request.startRow,
              params.context.search || undefined,
              params.context.deletedOnly,
            );
            if (response.errors) {
              throw new Error(response.errors[0].message);
            }

            const persons = response.data.persons;
            const receivedCount = persons.length;

            let rowCount = 0;
            if (endRowIndex <= currentRowCount) {
              rowCount = currentRowCount;
            } else {
              rowCount = receivedCount < expectedRowCount ? startRowIndex + receivedCount : endRowIndex + 1;
            }

            params.success({
              rowData: persons,
              rowCount,
            });
          } catch (err) {
            console.error(err);
            params.fail();
          }
        },
      });

      this.storeSubscription = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'person/ADD_TO_LIST':
            this.gridApi.applyServerSideTransaction({
              add: [mutation.payload],
            });
            break;

          case 'person/UPDATE_IN_LIST':
            this.gridApi.applyServerSideTransaction({
              update: [mutation.payload],
            });
            break;

          case 'person/REMOVE_FROM_LIST':
            this.gridApi.applyServerSideTransaction({
              remove: [mutation.payload],
            });
            break;

          default:
            break;
        }
      });
    },

    setColumns() {
      this.gridApi.setColumnDefs([
        {
          field: 'actions',
          headerName: 'Actions',
          width: 100,
          copyOnClick: false,
          cellRenderer: 'ActionsCellRenderer',
          cellRendererParams: {
            actions: [
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('persons.edit'),
                title: 'Edit',
                icon: 'fa-edit',
                onClick: id => this.editPerson(id),
              },
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('persons.remove'),
                title: 'Delete',
                icon: 'fa-trash-alt',
                onClick: id => this.deletePerson(id),
              },
              {
                show: entity => !!entity.deletedAt && this.currentAccount.hasPermission('vehicles.remove'),
                title: 'Restore',
                icon: 'fa-trash-restore-alt',
                onClick: id => this.restorePerson(id),
              },
            ],
          },
        },
        {
          field: 'name',
          headerName: 'Name',
        },
        {
          field: 'phone',
          headerName: 'Phone',
          valueFormatter: params => params.value ?? '-',
          tooltipValueGetter: params => params.data.phone,
        },
        {
          field: 'email',
          headerName: 'Email',
          valueFormatter: params => params.value ?? '-',
          tooltipValueGetter: params => params.data.email,
        },
        {
          field: 'status',
          headerName: 'Status',
          valueGetter: params => personService.getStatusTranslate(params.data.status),
        },
        {
          field: 'langs',
          headerName: 'Langs',
          valueGetter: params => params.data.langs.join(', '),
          valueFormatter: params => params.value || '-',
        },
        {
          field: 'canCrossBorder',
          hide: true,
          valueFormatter: params => (params.value ? 'Yes' : 'No'),
        },
        {
          field: 'hasTsaCard',
          hide: true,
          valueFormatter: params => (params.value ? 'Yes' : 'No'),
        },
        {
          field: 'hasTwicCard',
          hide: true,
          valueFormatter: params => (params.value ? 'Yes' : 'No'),
        },
        {
          field: 'hasHazmatCert',
          hide: true,
          valueFormatter: params => (params.value ? 'Yes' : 'No'),
        },
        {
          field: 'homeLocation.formattedAddress',
          headerName: 'Home location',
          valueFormatter: params => params.value || '-',
        },
        {
          field: 'hiredBy',
          headerName: 'Who hired?',
          valueGetter: params => params.data.hiredByAccount?.login,
          valueFormatter: params => params.value || '-',
        },
        {
          field: 'companyName',
          headerName: 'Company name',
          valueFormatter: params => params.value || '-',
        },
      ]);
    },

    saveColumnsState(columnApi) {
      const state = columnApi.getColumnState();
      localStorage.setItem('person-table-preset', JSON.stringify(state));
    },

    applyColumnsState(columnApi) {
      const state = localStorage.getItem('person-table-preset');
      if (!state) {
        return;
      }

      columnApi.applyColumnState({ state: JSON.parse(state), applyOrder: true });
    },

    createPerson() {
      this.$router.push({ name: 'person-create' });
    },

    editPerson(id) {
      this.$router.push({ name: 'person-edit', params: { id } });
    },

    async deletePerson(id) {
      try {
        const response = await deletePerson(id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Person was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    async restorePerson(id) {
      try {
        const response = await restorePerson(id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.gridApi.applyServerSideTransaction({ remove: [{ id }] });
        this.$toast.success('Person was restored.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    onSearch() {
      this.gridApi.refreshServerSide({ purge: true });
    },
  },
  mounted() {
    this.onSearch = debounce(this.onSearch.bind(this), 500);
  },
  unmounted() {
    this.storeSubscription?.();
  },
  watch: {
    deletedOnly: {
      handler(value) {
        this.gridApi.refreshServerSide({ purge: true });
      },
    },
  },
};
</script>

<style scoped>
.persons-page {
  width: 100%;
  padding: 1rem;
}

.person-create {
  margin: 0.2em;
}

.persons-page .search {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 2.2;
}
</style>
